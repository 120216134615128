// SIDEBAR MENU
let toggle = document.getElementById("sidebar-toggle");
let logo = document.getElementById("nav-logo");
let sidebar = document.getElementById("sidebar");
let sidebarOverlay = document.getElementsByClassName("sidebar-container")[0];
let sidebarItems = Array.from(document.querySelectorAll(".sidebar-menu a"));

function toggleSidebar() {
  document.body.classList.toggle('menu-visible');
}

// toggle.addEventListener('click', toggleSidebar);

sidebarItems.forEach(item => {
  item.addEventListener('click', toggleSidebar);
});


window.onclick = function(event) {
  if (event.target == sidebarOverlay ||
      event.target == toggle) {
    toggleSidebar();
  }
}

// OVERLAY MENU

let overlayToggle = document.querySelector(".menu-overlay-toggle");
let overlayMenu = document.querySelector(".menu-overlay");

function toggleOverlay() {
  overlayMenu.classList.toggle("visible");
  overlayToggle.classList.toggle("visible");  
}

overlayToggle.onclick = function(event) {
  toggleOverlay();
  if(this.classList.contains("visible")) {
    document.body.style("overflow: hidden; position: fixed")
  } else {
    document.body.style("overflow: auto; position: auto")
  }
}

overlayMenu.querySelectorAll("li").forEach(item => {
  item.addEventListener('click', toggleOverlay);
})